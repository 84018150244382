import React, { useState, useEffect } from 'react';
import './Matrice.css';
import dragSoundFile from '../sounds/drag.wav';
import dropSoundFile from '../sounds/ndrop.wav';
import successSoundFile from '../sounds/task-success.wav';
import deleteSoundFile from '../sounds/task-achieve.wav';
import pipeSoundFile from '../sounds/pipe-travel.wav';
import pipeImage from '../assets/pipe-icon.png';
import { useNavigate } from 'react-router-dom';

function Matrice() {
  const [tasks, setTasks] = useState(() => {
    const savedTasks = localStorage.getItem('tasks');
    return savedTasks ? JSON.parse(savedTasks) : [];
  });

  const dragSound = new Audio(dragSoundFile);
  const dropSound = new Audio(dropSoundFile);
  const successSound = new Audio(successSoundFile);
  const deleteSound = new Audio(deleteSoundFile);
  const pipeSound = new Audio(pipeSoundFile);

  const navigate = useNavigate();

  const [draggingTask, setDraggingTask] = useState(null);
  const [activeQuadrant, setActiveQuadrant] = useState('important-urgent');

  useEffect(() => {
    updateActiveQuadrant();
  }, [tasks]);

  const handlePipeClick = () => {
    pipeSound.play();
    navigate('/');
  };

  const handleDragStart = (taskId) => {
    dragSound.play();
    setDraggingTask(taskId);
  };

  const handleDrop = (targetQuadrant, dropIndex = null) => {
    if (!draggingTask) return;

    const updatedTasks = [...tasks];
    const draggedTaskIndex = updatedTasks.findIndex((task) => task.id === draggingTask);

    if (draggedTaskIndex === -1) return;

    const [draggedTask] = updatedTasks.splice(draggedTaskIndex, 1);

    if (draggedTask.quadrant !== targetQuadrant) {
      draggedTask.quadrant = targetQuadrant;
    }

    const quadrantTasks = updatedTasks.filter((task) => task.quadrant === targetQuadrant);

    if (dropIndex === null || dropIndex >= quadrantTasks.length) {
      quadrantTasks.push(draggedTask);
    } else if (dropIndex <= 0) {
      quadrantTasks.unshift(draggedTask);
    } else {
      quadrantTasks.splice(dropIndex, 0, draggedTask);
    }

    quadrantTasks.forEach((task, index) => {
      task.index = index;
    });

    const finalTasks = updatedTasks.filter((task) => task.quadrant !== targetQuadrant);
    finalTasks.push(...quadrantTasks);

    setTasks(finalTasks);
    localStorage.setItem('tasks', JSON.stringify(finalTasks));
    dropSound.play();
    setDraggingTask(null);
  };

  const toggleTaskCompletion = (taskId) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);
    localStorage.setItem('tasks', JSON.stringify(updatedTasks));
    successSound.play();
  };

  const deleteTask = (taskId) => {
    const updatedTasks = tasks.filter((task) => task.id !== taskId);
    setTasks(updatedTasks);
    localStorage.setItem('tasks', JSON.stringify(updatedTasks));
    deleteSound.play();
  };

  const updateActiveQuadrant = () => {
    const priorityOrder = [
      'important-urgent',
      'important-not-urgent',
      'not-important-urgent',
      'not-important-not-urgent',
    ];

    for (const quadrant of priorityOrder) {
      if (tasks.some((task) => task.quadrant === quadrant)) {
        setActiveQuadrant(quadrant);
        return;
      }
    }

    setActiveQuadrant(null);
  };

  const renderQuadrant = (quadrantKey, title, description, emoji) => {
    const quadrantTasks = tasks
      .filter((task) => task.quadrant === quadrantKey)
      .sort((a, b) => a.index - b.index);

    const isActive = activeQuadrant === quadrantKey;
    const quadrantClass = isActive ? 'red-border' : '';

    return (
      <div
        className={`quadrant ${quadrantKey} ${quadrantClass}`}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => handleDrop(quadrantKey)}
      >
        <div className="quadrant-header">
          {emoji} <span>{title}</span>
        </div>
        {isActive && <div className="static-text">{description}</div>}
        <div className="quadrant-content">
          {quadrantTasks.length > 0 ? (
            quadrantTasks.map((task, index) => (
              <div
                key={task.id}
                className={`task-item ${task.completed ? 'completed' : ''}`}
                draggable
                onDragStart={() => handleDragStart(task.id)}
                onDrop={(e) => handleDrop(quadrantKey, index)}
                onDragOver={(e) => e.preventDefault()}
              >
                <span>{task.task}</span>
                <span>{new Date(task.deadline).toLocaleDateString()}</span>
                {!task.completed ? (
                  <button
                    className="task-done-button"
                    onClick={() => toggleTaskCompletion(task.id)}
                  >
                    ✅
                  </button>
                ) : (
                  <button
                    className="task-delete-button"
                    onClick={() => deleteTask(task.id)}
                  >
                    🗑️
                  </button>
                )}
              </div>
            ))
          ) : (
            <div className="no-tasks">No tasks here yet.</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="matrix-container">
      <div className="pipe-container">
        <img
          src={pipeImage}
          alt="Navigate to Add Task"
          className="pipe-image"
          onClick={handlePipeClick}
        />
      </div>
      <div className="matrix">
        {renderQuadrant(
          'important-urgent',
          'Important & Urgent',
          'Nail these tasks ASAP to be free from them!',
          '🔥'
        )}
        {renderQuadrant(
          'important-not-urgent',
          'Important & Not Urgent',
          'The more you spend time here, the better you live.',
          '✅'
        )}
        {renderQuadrant(
          'not-important-urgent',
          'Not Important & Urgent',
          "Don't give in to useless emergencies.",
          '😤'
        )}
        {renderQuadrant(
          'not-important-not-urgent',
          'Not Important & Not Urgent',
          "Avoid being here... But don't forget to relax still! 😉",
          '👌'
        )}
      </div>
    </div>
  );
}

export default Matrice;