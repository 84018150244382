import React, { useState, useEffect } from "react";

function InfoIcon() {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showForm, setShowForm] = useState(false);

  const handleInfoIconClick = (e) => {
    e.stopPropagation();
    setIsTooltipVisible(true);
    setShowForm(false);
    console.log("Tooltip opened");
  };

  const handleOutsideClick = (e) => {
    if (
      !e.target.closest(".tooltip") &&
      !e.target.closest(".info-icon")
    ) {
      console.log("Clicked outside. Closing tooltip.");
      setIsTooltipVisible(false);
      setShowForm(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isTooltipVisible, showForm]);

  const handleEmailSubmit = async () => {
    if (!email.trim()) {
      setMessage("Please enter a valid email address.");
      return;
    }

    try {
      const response = await fetch("http://localhost:3001/api/send-activation-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage("Email sent successfully! Check your inbox.");
        console.log("Server response:", data);
      } else {
        setMessage("Failed to send email. Please try again.");
        console.error("Server error:", data);
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      console.error("Fetch error:", error);
    }
  };

  const handleLoginClick = () => {
    setShowForm(true);
  };

  const handleLoginSubmit = async () => {
    if (!email.trim() || !password.trim()) {
      setMessage("Please enter your email and password.");
      return;
    }

    try {
      const response = await fetch("http://localhost:3001/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage("Login successful! Enjoy now unlimited task saving 🎉 And more to come...");
        // Perform additional actions or redirect the user after successful login
        console.log("Server response:", data);
      } else {
        setMessage("Invalid email or password. Please try again.");
        console.error("Server error:", data);
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      console.error("Fetch error:", error);
    }
  };

  const styles = {
    container: {
      position: "absolute",
      top: "10px",
      right: "20px",
      display: "inline-block",
    },
    icon: {
      fontSize: "2rem",
      cursor: "pointer",
      color: "#333",
      display: "inline-block",
    },
    tooltip: {
      position: "absolute",
      top: "50px",
      left: "-180px",
      transform: "translateX(-20%)",
      backgroundColor: "#ffffff",
      color: "#333",
      padding: "15px",
      width: "250px",
      borderRadius: "8px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
      fontFamily: "Arial, sans-serif",
      fontSize: "0.95rem",
      lineHeight: "1.6",
      textAlign: "center",
      zIndex: 1000,
      opacity: isTooltipVisible ? 1 : 0,
      visibility: isTooltipVisible ? "visible" : "hidden",
      transition: "transform 0.3s ease, opacity 0.3s ease",
    },
    inputContainer: {
      marginTop: "10px",
    },
    emailInput: {
      width: "calc(100% - 30px)",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      fontSize: "0.95rem",
      outline: "none",
      boxSizing: "border-box",
    },
    button: {
      backgroundColor: "#4caf50",
      color: "#ffffff",
      padding: "10px 15px",
      borderRadius: "25px",
      fontWeight: "bold",
      cursor: "pointer",
      marginTop: "10px",
      transition: "background-color 0.3s ease",
    },
    message: {
      marginTop: "10px",
      color: "#4caf50",
      fontSize: "0.85rem",
      textAlign: "center",
    },
    loginLink: {
      marginTop: "15px",
      fontSize: "0.85rem",
      color: "#007BFF",
      textDecoration: "underline",
      cursor: "pointer",
    },
    loginButton: {
      backgroundColor: "#4caf50",
      color: "#ffffff",
      padding: "10px 15px",
      borderRadius: "25px",
      fontWeight: "bold",
      cursor: "pointer",
      marginTop: "10px",
      transition: "background-color 0.3s ease",
    },
  };

  return (
    <div style={styles.container}>
      {/* Info Icon */}
      <div style={styles.icon} onClick={handleInfoIconClick}>
        ℹ
      </div>

      {/* Tooltip */}
      {isTooltipVisible && (
        <div style={styles.tooltip} onClick={(e) => e.stopPropagation()}>
          <p>
            ☀️ Start your day with this app <br />
            📌 Pin it to your browser… and let productivity flow! <br />
            🌐 Your tasks stay saved for one week in your browser—come back
            anytime you need 🤗 <br />
            ❓ Need more?{" "}
          </p>

          {/* Email Input Field */}
          {!showForm && (
            <div style={styles.inputContainer}>
              <input
                type="email"
                placeholder="Enter your email"
                style={styles.emailInput}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          )}

          {/* Form for Email and Password */}
          {showForm && (
            <>
              <div style={styles.inputContainer}>
                <input
                  type="email"
                  placeholder="Email"
                  style={styles.emailInput}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div style={styles.inputContainer}>
                <input
                  type="password"
                  placeholder="Password"
                  style={styles.emailInput}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button style={styles.loginButton} onClick={handleLoginSubmit}>
                Login
              </button>
            </>
          )}

          {/* Create Account Button */}
          {!showForm && (
            <button style={styles.button} onClick={handleEmailSubmit}>
              👉 Create a free account
            </button>
          )}

          {/* Already Have an Account Link */}
          <div style={styles.loginLink} onClick={handleLoginClick}>
            Already have an account? Login
          </div>

          {/* Response Message */}
          {message && <div style={styles.message}>{message}</div>}
        </div>
      )}
    </div>
  );
}

export default InfoIcon;
