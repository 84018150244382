import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import AddTask from './components/AddTask';
import Matrice from './components/Matrice';
import InfoIcon from './components/InfoIcon';
import logo from './assets/whatdoIdonow.png'; // Nom de fichier du logo corrigé

function App() {
  const [tasks, setTasks] = useState([]);
  const [onboarding, setOnboarding] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(false); // Pour stocker le statut de permission
  const location = useLocation();

  const saveTasksToCookie = (updatedTasks) => {
    document.cookie = `tasks=${JSON.stringify(updatedTasks)};path=/;`;
  };

  const addTask = (newTask) => {
    const updatedTasks = [...tasks, newTask];
    setTasks(updatedTasks);
    saveTasksToCookie(updatedTasks);
    scheduleTaskNotifications(newTask);  // Planifier les notifications pour la tâche
  };

  const deleteTask = (taskId) => {
    const updatedTasks = tasks.filter((task) => task.id !== taskId);
    setTasks(updatedTasks);
    saveTasksToCookie(updatedTasks);
  };

  // Fonction pour demander la permission pour les notifications
  const requestNotificationPermission = () => {
    // Vérifie si la permission n'a pas été déjà demandée
    if (Notification.permission === 'default') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          setNotificationPermission(true); // Met à jour l'état pour dire que la permission est donnée
          console.log('Notification permission granted!');
        } else {
          console.log('Notification permission denied!');
        }
      });
    } else if (Notification.permission === 'granted') {
      setNotificationPermission(true); // Si la permission a déjà été donnée
    }
  };

  // Fonction pour envoyer une notification
  const sendNotification = (title, body) => {
    if (notificationPermission) {
      new Notification(title, { body });
    } else {
      console.log('Permission for notifications not granted yet.');
    }
  };

  // Fonction pour planifier les rappels de notification
  const scheduleTaskNotifications = (task) => {
    const taskTime = new Date(task.deadline);
    const taskTitle = `Next Task: ${task.task}`;
    
    // 15 minutes avant la tâche
    const reminder15min = new Date(taskTime.getTime() - 15 * 60 * 1000);
    setTimeout(() => {
      sendNotification(taskTitle, `Reminder: ${task.task} in 15 minutes!`);
    }, reminder15min - new Date());

    // 5 minutes avant la tâche
    const reminder5min = new Date(taskTime.getTime() - 5 * 60 * 1000);
    setTimeout(() => {
      sendNotification(taskTitle, `Reminder: ${task.task} in 5 minutes!`);
    }, reminder5min - new Date());

    // À l'heure de la tâche
    setTimeout(() => {
      sendNotification(taskTitle, `It's time to do: ${task.task}`);
    }, taskTime - new Date());
  };

  useEffect(() => {
    // Demander la permission pour les notifications dès que l'application est chargée
    requestNotificationPermission();

    // Vérifier si le token d'activation est dans l'URL
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      // Si un token est présent, lancer l'onboarding
      setOnboarding(true);
    }
  }, []);

  return (
    <div className="container">
      {location.pathname === "/" && (
        <img src={logo} alt="Logo WhatDoIDoNow" className="logo" />
      )}
      <InfoIcon />
      <Routes>
        <Route
          path="/"
          element={<AddTask tasks={tasks} addTask={addTask} />}
        />
        <Route
          path="/matrix"
          element={<Matrice tasks={tasks} deleteTask={deleteTask} />}
        />
      </Routes>

      {/* Afficher l'onboarding uniquement si l'utilisateur a cliqué sur le lien d'activation */}
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;